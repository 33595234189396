<template>
  <div>
    <base-page-title :titleText="pageTitle" />
    <service-predict-form
      :formType="formType"
      :service="service"
      @setService="updateService"
      @deleteService="deleteService"
      @cancel="goToServicesPage"
    />
  </div>
</template>

<script>
import ServicePredictForm from "../components/ServicesPredict/ServicePredictForm.vue";
export default {
  components: {
    ServicePredictForm,
  },
  data() {
    return {
      formType: "EDIT",
      pageTitle: "page.services.editService",
    };
  },
  computed: {
    service() {
      return {...this.$store.getters["servicePredict/getActiveService"]};
    },
    serviceId() {
      return this.service._id;
    },
    hasAnalyses() {
      return this.service.numAnalysis > 0;
    },
  },
  methods: {
    updateService(service) {
      this.$store.commit("loader/SET_LOADER", {});
      this.$store
        .dispatch("servicePredict/updateService", service)
        .catch(() => {})
        .finally(() => this.$store.dispatch("loader/close"));
    },
    deleteService(serviceId) {
      this.$store.commit("loader/SET_LOADER", {});
      this.$store
        .dispatch("servicePredict/deleteService", serviceId)
        .then(()=>{this.goToServicesPage()})
        .catch(() => {})
        .finally(() => this.$store.dispatch("loader/close"));
        
    },
    goToServicesPage() {
      this.$router.push({ name: "Services" });
    },
  },
};
</script>

<style scoped>
.icon {
  width: 20px;
  height: 20px;
  filter: invert(0%) sepia(85%) saturate(7500%) hue-rotate(173deg)
    brightness(87%) contrast(113%);
}
</style>
