<template>
  <div>
    <b-col class="col-12 col-md-8 p-3 mx-auto">
      <b-card no-body class="p-3 mb-2">
        <validation-observer ref="serviceForm" v-slot="{ valid }">
          <b-form>
            <b-row class="no-gutters">
              <b-col class="col-12">
                <b-form-group
                  label-class="font-weight-bold"
                  :label="$t('serviceName')"
                  label-for="name"
                >
                  <validation-provider
                    :name="$t('serviceName')"
                    :rules="{ required: true }"
                    v-slot="validationContext"
                  >
                    <b-form-input
                      id="name"
                      v-model="service.name"
                      :state="getValidationState(validationContext)"
                    ></b-form-input>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col class="col-12">
                <b-form-group
                  label-class="font-weight-bold"
                  :label="$t('description')"
                  label-for="description"
                >
                  <validation-provider
                    :name="$t('description')"
                    :rules="{ required: true }"
                    v-slot="validationContext"
                  >
                    <b-form-textarea
                      id="description"
                      v-model="service.description"
                      :state="getValidationState(validationContext)"
                      rows="3"
                    ></b-form-textarea>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col class="col-12 col-md-6">
                <b-form-group
                  label-class="font-weight-bold"
                  :label="$t('numAnalysis')"
                  label-for="numAnalysis"
                >
                  <validation-provider
                    :name="$t('numAnalysis')"
                    :rules="{ required: true, integer: true, min: 0 }"
                    v-slot="validationContext"
                  >
                    <b-form-input
                      id="numAnalysis"
                      v-model.number="service.numAnalysis"
                      :state="getValidationState(validationContext)"
                      type="number"
                      min="0"
                    ></b-form-input>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col class="col-12">
                <div
                  v-if="Number(service.numAnalysis) === 0"
                  class="text-muted mb-2"
                  style="margin-top: -10px"
                >
                  {{ $t("unlimitedAnalyses") }}
                </div>
              </b-col>
              <b-col class="col-12 col-md-6" v-if="!service.isRenewable">
                <b-form-group
                  label-class="font-weight-bold"
                  :label="$t('numDaysActive')"
                  label-for="numDaysActive"
                >
                  <validation-provider
                    :name="$t('numDaysActive')"
                    :rules="{ required: true, integer: true, min: 0 }"
                    v-slot="validationContext"
                  >
                    <b-form-input
                      id="numDaysActive"
                      v-model.number="service.numDaysActive"
                      :state="getValidationState(validationContext)"
                      type="number"
                      min="0"
                    ></b-form-input>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col class="col-12">
                <div
                  v-if="Number(service.numDaysActive) === 0"
                  class="text-muted mb-2"
                  style="margin-top: -10px"
                >
                  {{ $t("unlimitedDaysActive") }}
                </div>
              </b-col>

              <!-- Re-newable subscription -->
              <b-col class="col-12">
                <b-form-group label-class="font-weight-bold">
                  <b-form-checkbox v-model="service.isRenewable">
                    {{ $t("page.services.servicesPredictForm.renewableSubscription") }}
                  </b-form-checkbox>
                </b-form-group>
              </b-col>

              <b-col v-if="service.isRenewable" class="col-12 col-md-6">
                <b-form-group
                  label-class="font-weight-bold"
                  :label="$t('page.subscriptions.repeatPlan')"
                  label-for="repeatPlan"
                >
                  <validation-provider
                    :name="$t('repeatPlan')"
                    :rules="{ required: service.isRenewable }"
                    v-slot="validationContext"
                  >
                    <b-form-select
                      id="repeatPlan"
                      v-model="service.repeatPlan"
                      :state="getValidationState(validationContext)"
                      class="input-field"
                    >
                      <template v-slot:first>
                        <b-form-select-option :value="''" disabled>
                          {{ $t("selectAnOption") }}
                        </b-form-select-option>
                      </template>
                      <b-form-select-option
                        v-for="(option, index) in repeatPlanOptions"
                        :key="index"
                        :value="option.value"
                      >
                        {{ option.text }}
                      </b-form-select-option>
                    </b-form-select>

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col v-if="service.isRenewable && service.repeatPlan" class="col-12 col-md-6">
                <div>
                  <b-form-group
                    label-class="font-weight-bold"
                    :label="planText"
                    label-for="repeats"
                  >
                    <validation-provider
                      :name="planText"
                      :rules="{
                        required:
                          service.isRenewable && service.repeatPlan !== '',
                        integer: true,
                        min: 1,
                      }"
                      v-slot="validationContext"
                    >
                      <b-form-input
                        id="repeats"
                        v-model.number="service.repeats"
                        :state="getValidationState(validationContext)"
                        type="number"
                        min="1"
                      ></b-form-input>
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                </div>
              </b-col>

              <b-col v-if="service.isRenewable && service.repeatPlan==='custom'" class="col-12 col-md-6">
                <div>
                  <b-form-group
                    label-class="font-weight-bold"
                    :label="$t(`page.services.servicesPredictForm.repeatPlan.custom.subText`)"
                    label-for="repeatAfterDays"
                  >
                    <validation-provider
                      :name="$t(`page.services.servicesPredictForm.repeatPlan.custom.subText`)"
                      :rules="{
                        required:
                          service.isRenewable && service.repeatPlan !== '',
                        integer: true,
                        min: 1,
                      }"
                      v-slot="validationContext"
                    >
                      <b-form-input
                        id="repeatAfterDays"
                        v-model.number="service.repeatAfterDays"
                        :state="getValidationState(validationContext)"
                        type="number"
                        min="1"
                      ></b-form-input>
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                </div>
              </b-col>

            </b-row>
            <div class="mt-3"></div>
            <b-row v-if="formType === 'NEW'" class="no-gutters">
              <b-button class="ml-auto" :disabled="!valid" @click="setService">
                {{ actionBtnLabel }}
              </b-button>
            </b-row>
            <b-row v-else-if="formType === 'EDIT'" class="no-gutters">
              <b-col class="col-auto">
                <b-button
                  class="mr-1 mb-1"
                  variant="danger"
                  @click="openDeleteConfirmationDialog"
                >
                  {{ $t("button.delete") }}
                </b-button>
              </b-col>
              <b-col class="col-auto ml-auto mr-1 mb-1">
                <b-button variant="outline-primary" @click="cancel">
                  {{ $t("button.cancel") }}
                </b-button>
              </b-col>
              <b-col class="col-auto mr-1 mb-1">
                <b-button :disabled="!valid" @click="setService">
                  {{ actionBtnLabel }}
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card>
    </b-col>
  </div>
</template>

<script>
export default {
  props: {
    formType: {
      type: String,
      required: true,
    },
    service: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      defaultService: {
        name: "",
        description: "",
        numAnalysis: 0,
        numDaysActive: 0,
      },
      repeatPlanOptions: [
        { value: "weekly", text: this.$t("weekly") },
        { value: "monthly", text: this.$t("monthly") },
        { value: "yearly", text: this.$t("yearly") },
        { value: "custom", text: this.$t("custom") },
      ],
    };
  },
  mounted(){
    if(this.formType !== 'EDIT') return;
    this.service.isRenewable = !!this.service.repeatPlan;
  },
  computed: {
    actionBtnLabel() {
      return this.formType === "NEW"
        ? this.$t("button.createService")
        : this.$t("button.updateService");
    },
    planText() {
      return this.$t(`page.services.servicesPredictForm.repeatPlan.${this.service.repeatPlan}.text`);
    },
  },
  methods: {
    getValidationState({ dirty, validated, valid = null, untouched }) {
      if (!untouched) return valid ? null : valid;
      else return null;
    },
    setService() {
      this.$emit("setService", this.service);
    },
    openDeleteConfirmationDialog() {
      const serviceName = this.service.name;
      this.$store.commit("confirm/SET_CONFIRM", {
        titleText: "dialog.confirm.deleteServiceTitle",
        messageText: "dialog.confirm.deleteServiceMessage",
        messageParams: { serviceName },
        confirmBtnCallback: this.deleteService,
        type: "DELETE",
      });
    },
    deleteService() {
      this.$emit("deleteService", this.service._id);
    },
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>

<style scoped></style>
